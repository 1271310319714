"use client";

import { useEffect, useState } from "react";
import Image from "next/image";
import { getProfileImageUrl } from "./actions";

export default function ProfileImage({
  id,
  classNames,
  avatar_url = "",
}: {
  id: number;
  classNames?: string;
  avatar_url?: string | null;
}) {
  const [profileUrl, setProfileUrl] = useState<string>(
    "/images/profile-temp.jpg"
  );

  useEffect(() => {
    const fetchProfileImageUrl = async () => {
      if (!avatar_url) return;
      const profileImageUrl = await getProfileImageUrl(id, avatar_url, "users");
      setProfileUrl(profileImageUrl);
    };

    fetchProfileImageUrl();
  }, [id, avatar_url]);

  const imageContainerStyle = {
    aspectRatio: "1 / 1", // Creates a square aspect ratio
    borderRadius: "50%", // Makes the container circular
    overflow: "hidden", // Ensures the image doesn't bleed outside the border-radius
    display: "flex", // Used to center the image inside the container
    alignItems: "center", // Center vertically
  };

  return (
    <div
      className={`block relative ${classNames} max-w-xs`}
      style={imageContainerStyle}
    >
      <Image
        src={profileUrl}
        alt="Profile image"
        fill
        sizes="100%"
        className="object-cover absolute inset-0 rounded-full" // Apply rounded corners to the image as well
        priority
      />
    </div>
  );
}
