"use client";

import { useState, useEffect } from "react";
import { usePathname } from "next/navigation";
import Link from "next/link";
import {
  CalendarIcon,
  HomeIcon,
  BuildingOfficeIcon,
  UsersIcon,
  CogIcon,
} from "@heroicons/react/24/outline";

const navigationObject = [
  { name: "Analytics", href: "/", icon: HomeIcon, current: false },
  { name: "Beds", href: "/patients", icon: UsersIcon, current: false },

  { name: "Activity", href: "/activity", icon: CalendarIcon, current: false },
  { name: "Account", href: "/profile", icon: CogIcon, current: false },
];

const navigationAdmin = [
  {
    name: "Wards",
    href: "/wards",
    icon: BuildingOfficeIcon,
    current: false,
  },
  { name: "Users", href: "/users", icon: UsersIcon, current: false },
];

export default function SidebarMenu({
  user,
  setSidebarOpen,
  sidebarOpen,
}: {
  user: any;
  setSidebarOpen: any;
  sidebarOpen: boolean;
}) {
  const router = usePathname();
  const [navigation, setNavigation] = useState(navigationObject);
  const [previousRouter, setPreviousRouter] = useState(router);

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }
  function setActiveLink(path: string) {
    const updatednavigation = navigation.map((item) => {
      if (
        item.href === path ||
        (path.includes(item.href) && item.name === "Beds")
      ) {
        return { ...item, current: true };
      } else {
        return { ...item, current: false };
      }
    });
    setNavigation(updatednavigation);
  }
  useEffect(() => {
    if (router) {
      setActiveLink(router);
      if (sidebarOpen === true && router !== previousRouter) {
        setSidebarOpen(false);
      }
      setPreviousRouter(router);
    }
  }, [router]);

  return (
    <>
      {navigation.map((item) => (
        <Link
          key={item.name}
          href={item.href}
          className={classNames(
            item.current
              ? "bg-indigo-800 text-white"
              : "text-indigo-100 hover:bg-indigo-800 hover:text-white",
            "group flex w-full flex-col items-center rounded-md p-3 text-xs font-medium",
          )}
          aria-current={item.current ? "page" : undefined}
        >
          <item.icon
            className={classNames(
              item.current
                ? "text-white"
                : "text-indigo-300 group-hover:text-white",
              "h-6 w-6",
            )}
            aria-hidden="true"
          />
          <span className="mt-2">{item.name}</span>
        </Link>
      ))}
      <div className="h-4"></div>
      {user?.role === 1 && (
        <div className="border-t border-indigo-500 pt-8">
          <div className="text-center text-xs font-semibold leading-6 text-indigo-200">
            Administration
          </div>
          <div role="list" className="mt-2 space-y-1">
            {navigationAdmin.map((item) => (
              <Link
                key={item.name}
                href={item.href}
                className={classNames(
                  item.current
                    ? "bg-indigo-800 text-white"
                    : "text-indigo-100 hover:bg-indigo-800 hover:text-white",
                  "group flex w-full flex-col items-center rounded-md p-3 text-xs font-medium",
                )}
                aria-current={item.current ? "page" : undefined}
              >
                <item.icon
                  className={classNames(
                    item.current
                      ? "text-white"
                      : "text-indigo-300 group-hover:text-white",
                    "h-6 w-6",
                  )}
                  aria-hidden="true"
                />
                <span className="mt-2">{item.name}</span>
              </Link>
            ))}
          </div>
        </div>
      )}
    </>
  );
}
