"use client";

import { createClient } from "@/utils/supabase-browser";
import Link from "next/link";
import { useEffect, useState } from "react";
import ProfileImage from "../helpers/ProfileImage";

export default function SidebarProfile() {
  const [user, setUser] = useState<any>(null);
  const supabase = createClient();

  const signOut = async () => {
    await supabase.auth.signOut();
  };

  useEffect(() => {
    async function getSession() {
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (user) {
        const { data, error } = await supabase
          .from("users")
          .select(`id, first_name, last_name, profession, avatar_url`)
          .eq("id", user?.id)
          .single();
        if (error) throw error;

        setUser(data);
      }
    }

    getSession();
  }, []);

  const truncateEmail = (email: string) => {
    if (!email) return "";
    return email.length > 18 ? email.slice(0, 18) + "..." : email;
  };

  return (
    <div className="flex flex-shrink-0 bg-indigo-800 p-4">
      <Link href="/profile" className="group block w-full flex-shrink-0">
        <div className="flex flex-row justify-center items-center gap-2">
          <div className="h-8 w-8 relative">
            <ProfileImage
              id={user && user.id}
              avatar_url={user && user.avatar_url}
              classNames="inline-block rounded-full object-cover"
            />
          </div>
          <div className="flex flex-col gap-2">
            {/* <p className="text-sm font-medium text-white">
              {truncateEmail(user && user.email)}
            </p> */}
            <p>
              <span className="text-sm font-medium text-white leading-3">
                {user && user.first_name + " " + user.last_name}
              </span>
            </p>
          </div>
        </div>
        <p
          className="text-xs font-medium text-gray-300 group-hover:text-gray-200 text-center mt-4"
          onClick={() => signOut()}
        >
          Sign Out
        </p>
      </Link>
    </div>
  );
}
