// components/OtpInputForm.jsx
import { useState } from "react";
import { useRouter } from "next/navigation";
import { createClient } from "@/utils/supabase-browser";
import { SubmitButton } from "@/app/_components/form/SubmitButton";
import Notice from "../../notice";

export default function OtpForm() {
  const supabase = createClient();
  const router = useRouter();
  const [otp, setOtp] = useState("");
  const [email, setEmail] = useState(""); // Assuming you're tracking the user's email
  const [error, setError] = useState("");
  const [success, setSuccess] = useState(false);

  const handleVerifyOtp = async (e: React.FormEvent) => {
    e.preventDefault();

    const { data, error } = await supabase.auth.verifyOtp({
      email,
      token: otp,
      type: "email",
    });

    if (error) {
      setError(error.message);
      setSuccess(false);
    } else {
      setSuccess(true);
      setError("");
      router.refresh();
      /* router.push("/profile"); */
    }
  };

  return (
    <form onSubmit={handleVerifyOtp}>
      <div className="space-y-6">
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Email address
          </label>
          <div className="mt-1">
            <input
              id="email"
              name="email"
              type="email"
              title="Please enter your email"
              autoComplete="email"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
              className={
                "block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              }
            />
          </div>
        </div>
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-700"
          >
            Code
          </label>
          <input
            id="otp"
            type="text"
            className="block w-full appearance-none rounded-md border border-gray-300 px-3 py-2 placeholder-gray-400 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            value={otp}
            onChange={(e) => setOtp(e.target.value)}
            maxLength={6}
            pattern="\d{6}"
            required
          />
        </div>
        <SubmitButton
          title="Login with code"
          color="green"
          loading={false}
          className="cursor-pointer"
        />
        <Notice
          type={error ? "error" : success ? "success" : ""}
          title={error ? "Error" : success ? "Success" : ""}
          message={
            error
              ? error
              : success
                ? "Code Verified Successfully! Redirecting..."
                : ""
          }
        />
      </div>
    </form>
  );
}
