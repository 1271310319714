"use client";

import { useRouter } from "next/navigation";
import {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { createClient } from "../../utils/supabase-browser";
import { UserType } from "@/lib/types";

interface ContextProps {
  supabase: any;
  user: UserType | null;
}

const Context = createContext<ContextProps>({
  supabase: createClient(),
  user: null,
});

type Props = {
  children: ReactNode;
  accessToken?: string;
};

export default function SupabaseProvider({ children, accessToken }: Props) {
  const router = useRouter();
  const [supabase] = useState(() => createClient());
  const [user, setUser] = useState<UserType | null>(null);

  useEffect(() => {
    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((event: any, session: any) => {
      if (session?.access_token !== accessToken) {
        router.refresh();
      }
    });
    return () => subscription.unsubscribe();
  }, [accessToken, supabase, router]);

  return (
    <Context.Provider value={{ supabase, user }}>
      <>{children}</>
    </Context.Provider>
  );
}

export const useSupabase = () => useContext(Context);
